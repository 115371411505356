<template>
  <div class="logo-wrapper">
    <router-link to="/">
      <div class="logo-iot" v-if="$store.getters.userRole === 'top_agent'" style="background-color:rgb(4, 21, 40);">
        <div class="logo-icon-iot">
        </div>
        <div class="logo-words-iot" style="color:#fff;font-size: 22px; font-weight: 700">
          物联网管理平台
        </div>
      </div>
      <div class="logo" v-else style="background-color:rgb(4, 21, 40);">
        <div class="logo-icon">
          <!--          <img-->
          <!--            src="@/assets/images/logo_icon.svg"-->
          <!--            alt="logo"-->
          <!--          >-->
        </div>
        <div class="logo-words" style="color:#fff;">
          物联网管理平台
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'LeftTopLogo'
}
</script>

<style lang="less" scoped>
// logo 区域
.logo-wrapper {
  height: 64px;
  overflow: hidden;
  line-height: 64px;
  //border-right: 1px solid #e8e8e8;
  //border-bottom: 1px solid #e8e8e8;

  .logo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;

    .logo-words {
      color: @primary-color;
      font-size: 19px;
      &:hover {
        color: @primary-color;
      }
    }
  }

  // logo
  .logo-iot {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    img {
      height: 55px;
    }
  }
}

// 左侧菜单收缩时隐藏 logo 文字部分
.ant-layout-sider-collapsed {
  .logo-iot .logo-words-iot, .logo .logo-words {
    display: none;
  }
}
</style>

