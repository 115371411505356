<template>
  <a-menu
    theme="dark"
    mode="inline"
    :open-keys="openedMenuKeys"
    v-model="currentMenu"
    class="side-menu-wrapper"
    @openChange="onOpenChange"
    style="background-color:rgb(4, 21, 40)"
  >

    <a-menu-item key="home">
      <router-link to="/">
        <a-icon type="bank" />
        <span>首页</span>
      </router-link>
    </a-menu-item>

    <a-sub-menu key="purchase" v-if="isShowMenu('menu:customer/purchase')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="shopping-cart" />
        <span>采购管理</span>
      </span>

      <a-menu-item key="goods" v-if="isShowMenu('customer/goods')">
        <router-link :to="{ name: 'goods'}">
          <span>采购</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="cart" v-if="isShowMenu('customer/cart')">
        <router-link :to="{ name: 'cart'}">
          <span>购物车</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="agent_purchase_orders" v-if="isShowMenu('customer/agent_purchase_orders')">
        <router-link :to="{ name: 'agent_purchase_orders'}">
          <span>订单</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="cards" v-if="isShowMenu('menu:customer/cards')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="pic-right" />
        <span>SIM卡管理</span>
      </span>

      <a-menu-item key="sim_cards">
        <router-link :to="{ name: 'sim_cards' }">
          <span>SIM卡列表</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="pools">
        <router-link :to="{ name: 'pools' }">
          <span>流量池</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="service_cycle_manage">
        <router-link :to="{ name: 'service_cycle_unexpired' }">
          <span>服务周期</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="oc_cards" v-if="isShowMenu('menu:customer/oc_cards')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="pic-center" />
        <span>三方SIM卡管理</span>
      </span>

      <a-menu-item key="oc_sim_cards">
        <router-link :to="{ name: 'oc_sim_cards' }">
          <span>三方SIM卡列表</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="smses" v-if="isShowMenu('menu:customer/smses')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="message" />
        <span>短信管理</span>
      </span>

      <a-menu-item key="smses_overview">
        <router-link :to="{ name: 'smses_overview' }">
          <span>短信总览</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="sms_delivery_records">
        <router-link :to="{ name: 'sms_delivery_records' }">
          <span>短信下发查询</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="sms_reception_records">
        <router-link :to="{ name: 'sms_reception_records' }">
          <span>短信上行查询</span>
        </router-link>
      </a-menu-item>

      <!-- <a-menu-item key="sms_template_records">
        <router-link :to="{ name: 'sms_template_records' }">
          <span>短信模版管理</span>
        </router-link>
      </a-menu-item> -->
    </a-sub-menu>

    <a-sub-menu key="sales" v-if="isShowMenu('menu:customer/sales')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="shopping" />
        <span>销售管理</span>
      </span>

      <a-menu-item key="agencies" v-if="isShowMenu('customer/agencies')">
        <router-link :to="{ name: 'agencies' }">
          <span>客户列表</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="agents_products" v-if="isShowMenu('customer/agents_products')">
        <router-link :to="{ name: 'agents_products' }">
          <span>套餐列表</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="finance" v-if="isShowMenu('menu:customer/finance')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="account-book" />
        <span>财务管理</span>
      </span>

      <a-menu-item key="finance_overview" v-if="isShowMenu('customer/finance_overview')">
        <router-link :to="{ name: 'finance_overview' }">
          <span>财务总览</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="monthly_bills" v-if="isShowMenu('customer/agent_monthly_bills')">
        <router-link :to="{ name: 'monthly_bills' }">
          <span>月账单</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="agent_consumption_bills" v-if="isShowMenu('customer/agent_consumption_bills')">
        <router-link :to="{ name: 'agent_consumption_bills' }">
          <span>账单明细</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="transfers" v-if="isShowMenu('customer/transfers')">
        <router-link :to="{ name: 'transfers' }">
          <span>收款明细</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="balance_statistics" v-if="isShowMenu('customer/balance_statistics')">
        <router-link :to="{ name: 'balance_statistics' }">
          <span>余额</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="commission" v-if="isShowMenu('menu:customer/commission') && this.agentType == 1">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="money-collect" />
        <span>分佣管理</span>
      </span>

      <a-menu-item key="monthly_bills_commission" v-if="isShowMenu('customer/agent_monthly_bills')">
        <router-link :to="{ name: 'monthly_bills_commission' }">
          <span>月订单</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="agent_consumption_bills_commission" v-if="isShowMenu('customer/agent_consumption_bills')">
        <router-link :to="{ name: 'agent_consumption_bills_commission' }">
          <span>订单明细</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="commission_statistics" v-if="isShowMenu('customer/commission_statistics')">
        <router-link :to="{ name: 'commission_statistics' }">
          <span>佣金</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="queries" v-if="isShowMenu('menu:customer/queries')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="database" />
        <span>系统查询</span>
      </span>

      <a-menu-item key="batch_operation_records">
        <router-link :to="{ name: 'batch_operation_records'}">
          <span>批量操作查询</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu key="settings" v-if="isShowMenu('menu:customer/queries')">
      <span
        slot="title"
        class="submenu-title-wrapper"
      >
        <a-icon type="setting" />
        <span>账户设置</span>
      </span>

      <a-menu-item key="enterprise_qualifications">
        <router-link :to="{ name: 'enterprise_qualifications'}">
          <span>企业信息</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="api_info" v-if="isShowMenu('agents/api_info')">
        <router-link :to="{ name: 'api_info'}">
          <span>API 信息</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="receive_addresses">
        <router-link :to="{ name: 'receive_addresses'}">
          <span>收货信息</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'SideMenu',
  data() {
    return {
      openedMenuKeys: this.$store.getters.currentMenus, // 当前打开的菜单keys(包含父子菜单)
      rootSubmenuKeys: ['home', 'purchase', 'cards', 'sales', 'finance', 'queries', 'settings']
    }
  },
  computed: {
    // 当前选中的菜单
    currentMenu: {
      get() {
        return this.$store.getters.currentMenus
      },
      set(val) {
        // setActiveMenu 中设置
        // 这里给一个空的 setter, 解决在新标签打开菜单报错问题
      }
    },
    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.openedMenuKeys = this.currentMenu
      }
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openedMenuKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openedMenuKeys = openKeys
      } else {
        this.openedMenuKeys = latestOpenKey ? [latestOpenKey] : []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.side-menu-wrapper {
  border-right-color: transparent;
  height: calc(100vh - 64px);
  overflow: auto;
  -ms-overflow-style: none;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
